import { mergeSelectorConfiguration } from 'helper/mergeHelper';
import config, { SelectorConfiguration } from 'selectorConfiguration';

let audiSelectors: SelectorConfiguration = {
	startup: [],
	'input[type="radio"]:not(.dynamicTreeNodeAttribute, .selectionInput), input[type="checkbox"]:not(.dynamicTreeNodeAttribute, .selectionInput)': {
		1: {
			name: 'CustomFormElements',
			requirePath: 'plugins/jquery.customFormElements',
		},
	},
	'select[size]:not(.dynamicTreeNodeFilterOption,.dynamicTreeNodeAttribute,.noCustomStyling)': {
		1: {
			name: 'SelectBoxV2',
			requirePath: 'plugins/jquery.selectBoxV2',
		},
	},
	'div.fileUpload': {
		1: {
			name: 'CustomFileUpload',
			requirePath: 'plugins/jquery.customFileUpload',
		},
	},
	'.truncatable': {
		1: {
			name: 'SelectTextTruncator',
			requirePath: 'plugins/textTruncator/jquery.selectTextTruncator',
		},
	},
	'.superviewButton': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: 'div.resultSet .resultSetItem',
			configurationName: 'Lightbox',
		},
	},
	'#pictureDbPreviewButton': {
		1: {
			name: 'LightboxBildDB',
			requirePath: 'plugins/lightbox/jquery.lightboxBildDB',
			initializationContext: 'div.picturePreview.hasLightbox',
			configurationName: 'BildDBLightbox',
		},
	},
	'.ym-col3 .resultImage a': {},
	'.resultSet.superview': {
		1: {
			name: 'SuperviewPreviewSwitch',
			requirePath: 'plugins/jquery.superviewPreviewSwitch',
		},
	},
	'#breadCrumb': {
		1: {
			name: 'Breadcrumb',
			requirePath: 'customer/plugins/jquery.breadcrumb',
		},
	},
	'div.overviewArea.newsletter, div.overviewArea.newslettertm': {
		1: {
			name: 'NewsletterEditPreview',
			requirePath: 'plugins/editView/jquery.newsletterEditPreview',
			initializationContext: '#editMask',
		},
	},
	'#editMask:not(.newsletter)': {
		1: {
			name: 'TextareaLinebreak',
			requirePath: 'plugins/jquery.textarea-linebreak',
		},
	},
	'.anythingSlider': {
		1: {
			name: 'Slider',
			requirePath: 'plugins/slider/jquery.slider',
		},
	},
	'.sourceDestinationSet': {
		1: {
			name: 'SourceDestinationSet',
			requirePath: 'plugins/jquery.sourceDestinationSet',
		},
	},
	'.mpNewsletterPreview': {
		1: {
			name: 'MarketingPlanerNewsletterPreview',
			requirePath: 'plugins/marketingplaner-frame',
		},
	},
	'textarea.hasSmileypicker': {
		1: {
			name: 'SmileyPicker',
			requirePath: 'plugins/jquery.smileyPicker',
		},
	},
};

export default mergeSelectorConfiguration(config, audiSelectors);
